<div class="main_div">
  <div class="">
    <span class="header_ngo">Donation Management / </span>
    <span class="header">All Fundraisers</span>
  </div>

  <div class="top_header">
    <span class="ngo_name">All Fundraisers</span>
  </div>

  <div
    class="tab_div"
    [ngStyle]="{
      'justify-content': activeTab === 1 ? 'space-between' : 'flex-end'
    }"
  >
    <dx-date-range-box
      *ngIf="activeTab === 1"
      class="time_line"
      [startDate]="oneYearAgo"
      [endDate]="today"
      labelMode="floating"
      [min]="oneYearAgo"
      [max]="today"
      displayFormat="dd MMM, yyyy"
      (onValueChanged)="filterDonations($event)"
    ></dx-date-range-box>
    <div class="tab_button_div">
      <div class="tab" [class.active]="activeTab === 1" (click)="activeTab = 1">
        <img
          [src]="
            activeTab === 1
              ? '/assets/images/icon/dashboard_active_icon.svg'
              : '/assets/images/icon/dashboard_inactive_icon.svg'
          "
        />
        <div>Dashboard</div>
      </div>
      <div class="tab" [class.active]="activeTab === 2" (click)="activeTab = 2">
        <img
          [src]="
            activeTab === 2
              ? '/assets/images/icon/lists_icon_active.svg'
              : '/assets/images/icon/lists_icon_inactive.svg'
          "
        />
        <div>All Fundraisers</div>
      </div>
    </div>
  </div>

  @if (activeTab === 1) {
  <div class="row mt_32">
    <div class="col-sm-12 col-lg-12 col-xl-4 mb_32">
      <div class="card">
        <div class="d-flex justify-content-between">
          <div class="total_donation">Total Fund Raised</div>
          <img src="/assets/images/icon/donate_hand_icon.svg" />
        </div>
        <div class="number">
          ৳ {{ fundraiserDetails?.total_fund_raised | number : "1.0-0" }}
        </div>
        <!-- <div class="header text-dark">
          <span class="growth" data-value="+3">+3.21% </span
          ><span>growth last year</span>
        </div> -->
      </div>
    </div>
    <div class="col-sm-12 col-lg-12 col-xl-4 mb_32">
      <div class="card">
        <div class="d-flex justify-content-between">
          <div class="total_donation">Total Members</div>
          <img src="/assets/images/icon/user_group_icon.svg" />
        </div>
        <div class="number">
          {{ fundraiserDetails?.total_members | number }}
        </div>
        <!-- <div class="header text-dark">
          <span class="growth" data-value="+3">+3.21% </span
          ><span>growth last year</span>
        </div> -->
      </div>
    </div>
    <div class="col-sm-12 col-lg-12 col-xl-4 mb_32">
      <div class="card">
        <div
          class="d-flex justify-content-between"
          style="padding-right: 16px; padding-bottom: 16px"
        >
          <div class="ngo_name fw_500">Top Team</div>
          <img
            class="cursor"
            src="/assets/images/icon/maximize_icon.svg"
            (click)="topTeamPopup = true"
          />
        </div>

        <div id="scrollableDiv" style="padding-right: 16px">
          <div
            class="donation_item"
            *ngFor="let team of topTeams; let i_c = index"
          >
            <div class="d-flex align-items-center">
              @if(i_c <= 2) {
              <img
                class="campaign_img"
                [src]="'/assets/images/icon/trophy_' + i_c + '.svg'"
              />
              } @else {
              <div class="index">{{ i_c + 1 }}</div>
              }
              <img
                class="campaign_img"
                [src]="team?.team_logo"
                alt="Team cover"
              />
              <div class="d-flex flex-grow-1 justify-content-between">
                <div class="d-flex flex-column gap-1">
                  <div class="team_name">
                    {{ team?.team_name }}
                  </div>
                  <div class="no_members">
                    {{ (team?.total_member | number) || 0 }}
                    {{ team?.total_member > 1 ? "Members" : "Member" }}
                  </div>
                </div>
                <div class="d-flex flex-column gap-1 align-items-end">
                  <div class="no_donors">
                    <img
                      [width]="24"
                      [height]="24"
                      src="/assets/images/icon/user_group_icon.svg"
                    />
                    ({{ (team?.total_donors | number) || 0 }})
                  </div>
                  <div class="no_members">
                    ৳ {{ team?.fund_raised | number }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="row">
    <div class="h_541 col-sm-12 col-lg-12 col-xl-4 mb_32">
      <dx-chart id="chart" class="chart_div" [dataSource]="donations">
        <dxo-title text="Donation Over Time" horizontalAlignment="left">
          <dxo-font family="Inter" size="24" weight="500"></dxo-font>
        </dxo-title>
        <dxo-zoom-and-pan argumentAxis="both"></dxo-zoom-and-pan>
        <dxo-scroll-bar [visible]="true"></dxo-scroll-bar>
        <dxo-animation
          easing="linear"
          [duration]="500"
          [maxPointCountSupported]="300"
        >
        </dxo-animation>
        <dxo-legend
          verticalAlignment="top"
          horizontalAlignment="right"
          position="inside"
          backgroundColor="none"
        >
        </dxo-legend
        ><dxi-series
          argumentField="key"
          valueField="value"
          name="Donations"
          type="bar"
          color="#0054a6"
          barWidth="28"
          cornerRadius="0px"
        >
        </dxi-series>
        <dxo-argument-axis
          [visualRange]="{
            length: 5
          }"
        >
        </dxo-argument-axis>
        <dxo-common-series-settings
          argumentField="key"
          type="bar"
          hoverMode="allArgumentPoints"
          selectionMode="allArgumentPoints"
        >
          <dxo-label
            [visible]="true"
            [format]="{
              type: 'fixedPoint',
              precision: 0
            }"
          >
          </dxo-label>
        </dxo-common-series-settings>
      </dx-chart>
    </div>

    <div class="h_541 col-sm-12 col-lg-12 col-xl-4 mb_32">
      <div class="card h_541 justify-content-between">
        <div class="d-flex justify-content-between">
          <div class="total_donation">Total Fundraiser Teams</div>
          <img src="/assets/images/icon/user_group_icon.svg" />
        </div>
        <div class="number">{{ fundraiserDetails?.total_team | number }}</div>
        <!-- <div class="header text-dark">
          <span class="growth" data-value="-3">-3% </span
          ><span>growth last year</span>
        </div> -->
      </div>
    </div>

    <div class="h_541 col-sm-12 col-lg-12 col-xl-4 mb_32">
      <div class="chart_div">
        <div
          class="d-flex justify-content-between mb_32"
          style="padding-right: 16px"
        >
          <div class="ngo_name fw_500">Top Fundraiser</div>
          <img
            class="cursor"
            src="/assets/images/icon/maximize_icon.svg"
            (click)="topFundraiserPopup = true"
          />
        </div>

        <div id="scrollableDiv" style="padding-right: 16px">
          <div
            class="donation_item"
            *ngFor="let fundraiser of topFundraisers; let i_c = index"
          >
            <div class="d-flex align-items-center">
              @if(i_c <= 2) {
              <img
                class="campaign_img"
                [src]="'/assets/images/icon/trophy_' + i_c + '.svg'"
              />
              } @else {
              <div class="index">{{ i_c + 1 }}</div>
              }
              <img
                class="campaign_img"
                [src]="
                  fundraiser?.profile_img || './assets/images/round_profile.png'
                "
                alt="Team cover"
              />
              <div class="d-flex flex-grow-1 justify-content-between">
                <div class="d-flex flex-column gap-1">
                  <div class="team_name">
                    {{ fundraiser?.title }}
                  </div>
                  <div class="no_members">
                    <div class="fw-bolder">
                      {{ fundraiser?.fundraiser_name }}
                    </div>
                    <div>৳ {{ fundraiser?.total_fund_raised | number }}</div>
                  </div>
                </div>
                <div class="d-flex flex-column gap-1 align-items-end">
                  <div class="no_donors">
                    <img
                      [width]="24"
                      [height]="24"
                      src="/assets/images/icon/user_group_icon.svg"
                    />
                    ({{ fundraiser?.total_donors | number }})
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  } @if(activeTab === 2) {
  <app-all-fundraisers></app-all-fundraisers>
  }
</div>

<dx-popup
  [(visible)]="topTeamPopup"
  [width]="800"
  [height]="836"
  [showTitle]="true"
  [showCloseButton]="true"
  [dragEnabled]="false"
  [resizeEnabled]="false"
  position="center"
  [enableBodyScroll]="false"
  [hideOnOutsideClick]="false"
>
  <div
    class="chart_div p-0 h-100"
    style="background-color: #f1f2f5; margin-inline: 12px"
  >
    <div class="d-flex justify-content-center mb_32">
      <div class="ngo_name fw_500">Top Fundraiser Team</div>
    </div>

    <div class="project_div">
      <div class="d-flex mb-2">
        <div class="total_donation flex-grow-1">Name</div>
        <div class="total_donation" style="width: 150px">Contribution</div>
        <div class="total_donation" style="width: 124px; text-align: right">
          Supporters
        </div>
      </div>

      <div
        class="donation_item mb-3"
        *ngFor="let team of allTopTeams; let i_c = index"
      >
        <div class="d-flex align-items-center popup_row">
          @if(i_c <= 2) {
          <img
            class="campaign_img"
            [src]="'/assets/images/icon/trophy_' + i_c + '.svg'"
          />
          } @else {
          <div class="index">{{ i_c + 1 }}</div>
          }
          <img
            class="campaign_img"
            [src]="team?.team_logo || './assets/images/round_profile.png'"
            alt="Team cover"
          />
          <div class="d-flex flex-grow-1 justify-content-between">
            <div class="team_name f">
              <div>{{ team?.team_name }}</div>
              <div class="fw-normal">
                {{ team?.total_donors | number }}
                {{ team?.total_donors > 1 ? "Members" : "Member" }}
              </div>
            </div>
            <div class="d-flex gap-1">
              <div class="no_donors" style="width: 150px">
                <img
                  [width]="24"
                  [height]="24"
                  src="/assets/images/icon/heart_hand.svg"
                />
                (৳ {{ team?.fund_raised | number }})
              </div>
              <div class="no_donors" style="width: 110px; justify-content: end">
                <img
                  [width]="24"
                  [height]="24"
                  src="/assets/images/icon/user_group_icon.svg"
                />
                ({{ (team?.total_donors | number) || 0 }})
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <button
      class="load_more"
      (click)="getTopTeams()"
      [disabled]="disableTopTeamLoadMore"
    >
      Load More
    </button>
  </div>
</dx-popup>

<dx-popup
  [(visible)]="topFundraiserPopup"
  [width]="800"
  [height]="800"
  [showTitle]="true"
  [showCloseButton]="true"
  [dragEnabled]="false"
  [resizeEnabled]="false"
  position="center"
  [enableBodyScroll]="false"
  [hideOnOutsideClick]="false"
>
  <div
    class="chart_div p-0 h-100"
    style="background-color: #f1f2f5; margin-inline: 12px"
  >
    <div class="d-flex justify-content-center mb_32">
      <div class="ngo_name fw_500">Top Fundraisers</div>
    </div>

    <div class="project_div" style="height: 590px !important;">
      <div class="d-flex mb-2">
        <div class="total_donation flex-grow-1">Name</div>
        <div class="total_donation" style="width: 150px">Contribution</div>
        <div class="total_donation" style="width: 124px; text-align: right">
          Supporters
        </div>
      </div>

      <div
        class="donation_item mb-3"
        *ngFor="let fundraiser of allTopFundraisers; let i_c = index"
      >
        <div class="d-flex align-items-center popup_row">
          @if(i_c <= 2) {
          <img
            class="campaign_img"
            [src]="'/assets/images/icon/trophy_' + i_c + '.svg'"
          />
          } @else {
          <div class="index">{{ i_c + 1 }}</div>
          }
          <img
            class="campaign_img"
            [src]="
              fundraiser?.profile_img || './assets/images/round_profile.png'
            "
            alt="Team cover"
          />
          <div class="d-flex flex-grow-1 justify-content-between">
            <div class="team_name f">
              <div>{{ fundraiser?.fundraiser_name }}</div>
              <div class="fw-normal">
                {{ fundraiser?.total_donors | number }}
                {{ fundraiser?.total_donors > 1 ? "Members" : "Member" }}
              </div>
            </div>
            <div class="d-flex gap-1">
              <div class="no_donors" style="width: 150px">
                <img
                  [width]="24"
                  [height]="24"
                  src="/assets/images/icon/heart_hand.svg"
                />
                (৳ {{ fundraiser?.total_fund_raised | number }})
              </div>
              <div class="no_donors" style="width: 110px; justify-content: end">
                <img
                  [width]="24"
                  [height]="24"
                  src="/assets/images/icon/user_group_icon.svg"
                />
                ({{ fundraiser?.total_donors | number }})
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <button
      class="load_more"
      (click)="getTopFundraisers()"
      [disabled]="disableTopFundraiserLoadMore"
    >
      Load More
    </button>
  </div>
</dx-popup>
