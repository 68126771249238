import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { AuthService } from 'src/app/shared/services/auth/auth.service';
import { LocalStorageService } from 'src/app/shared/services/local-storage/local-storage.service';
import { UtilitiesService } from 'src/app/shared/services/utilities/utilities.service';

@Component({
  selector: 'app-super-admin-header',
  templateUrl: './super-admin-header.component.html',
  styleUrl: './super-admin-header.component.scss',
})
export class SuperAdminHeaderComponent implements OnInit {
  public sessionUser = this.localStorageService.getSessionUser();
  public userNameFirstLetter: string;
  public currentUrl: string;
  private authSubscription!: Subscription;

  constructor(
    public readonly router: Router,
    public readonly authService: AuthService,
    public readonly utilitiesService: UtilitiesService,
    public readonly localStorageService: LocalStorageService
  ) {}

  ngOnInit(): void {
    this.userNameFirstLetter = this.sessionUser.name.charAt(0);
    this.authSubscription = this.authService.authState$.subscribe();
    //this.getCurrentUrl();
  }

  private getCurrentUrl(): void {
    const value = this.router.url;
    // if (value.includes('bookmarked-ngos')) {
    //   this.currentUrl = 'Bookmarked NGOs';
    // }
  }

  public logOut(): void {
    this.authService.logout();
    this.router.navigateByUrl('/');
  }
  
  ngOnDestroy() {
    if (this.authSubscription) {
      this.authSubscription.unsubscribe();
    }
  }
}
